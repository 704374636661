import React, { Fragment, useState } from 'react';
import { string } from 'prop-types';
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import useMutation from '@/hooks/useMutation';
import useGuestInfo from '@/hooks/useGuestInfo';

import { API_HOSTNAME, API_RSVP_TABLE } from '@/constants';
import { IMG_PATTERN_SQUARE } from '@/constants/assets';
import { BUTTON_PROPS } from '@/constants/colors';
import { txtForm as txt } from './locales';
import {
  FORM_LABEL_PROPS,
  INPUT_COMMON_PROPS,
  SELECT_COMMON_PROPS,
  TYPE,
  ERROR_TYPE,
  ERROR_PROPS,
} from './types';
import useLang from '@hooks/useLang';
import { ENABLE_PARTNER_MODE } from '@/constants/feature-flags';
import usePartner from '@hooks/usePartner';

const enc = (string) => encodeURIComponent(string);

/**
 * function to render RSVP component
 * @returns {JSX.Element}
 * @author idindrakusuma
 */
function RSVPSection({ ...rest }) {
  const toast = useToast();
  const { name: finalName, hp: finalHp, desc, is_cetak } = useGuestInfo();
  const lang = useLang();
  const partner = usePartner();

  const [onSentConfirmation, loading] = useMutation(API_HOSTNAME, API_RSVP_TABLE, 'insert');

  const [name, setName] = useState(() => finalName);
  const [phone, setPhone] = useState(() => finalHp);
  const [address, setAddress] = useState('-');
  const [attended, setAttended] = useState(TYPE.YES);
  const [bringPartner, setBringPartner] = useState(TYPE.YES);
  const [errorType, setErrorType] = useState(ERROR_TYPE);

  /**
   * function to set state
   * @param {object} e - html event value
   * @param {function} setState
   * @param {string} typeState
   * @returns
   */
  const onSetState = (e, setState, typeState) => {
    const value = e.target.value;
    setState(value);

    if (typeState === 'email') {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
        setErrorType({ ...errorType, email: 'Invalid email address' });
        return;
      }
    }

    if (typeState === 'phone') {
      if (!/\d+/.test(value) || value.length > 13) {
        setErrorType({ ...errorType, phone: 'Invalid phone number' });
        return;
      }
    }

    setErrorType(ERROR_TYPE);
  };

  /**
   * function to submit to BE with check the form value first
   */
  const onSubmitForm = async () => {
    if (errorType.name || errorType.phone || errorType.address) return;

    if (!name || !phone || !address) {
      setErrorType({
        name: !name ? txt.required[lang] : '',
        phone: !phone ? txt.required[lang] : '',
        address: !address ? txt.required[lang] : '',
      });

      return;
    }

    const query =
      `nama=${enc(name)}` +
      `&hp=${phone}` +
      `&hadir=${enc(attended)}` +
      `&jumlah_tamu=${bringPartner}` +
      `&is_cetak=${is_cetak}` +
      `&desc=${enc(desc)}`;

    const result = await onSentConfirmation(query);

    if (result.success) {
      toast({
        title: txt.success[lang],
        description: txt.msgSuccess[lang],
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setName('');
      setPhone('');
      setAddress('-');
    } else {
      toast({
        title: 'Oops!',
        description: txt.msgError[lang],
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      bgColor="bgPrimary"
      padding="62px 24px"
      boxShadow="xl"
      bgSize="80%"
      bgImage={`url(${IMG_PATTERN_SQUARE})`}
      {...rest}
    >
      <Box
        boxShadow="2xl"
        padding="8px"
        bgColor="bgPrimary"
        borderRadius="16px"
        border="6px solid"
        borderColor="bgSecondary"
      >
        <WithAnimation>
          <Box padding="24px 16px" width="100%">
            {/* Title & Desc Section */}
            <Box textAlign="center" color="mainColorText">
              <Text fontFamily="heading" fontSize="4xl">
                {txt.title[lang]}
              </Text>
              <Text fontSize="md" margin="16px 0 24px 0">
                {txt.desc[lang]}
              </Text>
            </Box>

            {/* Form Sections - Name */}
            <FormControl margin="8px 0" isInvalid={errorType.name}>
              <FormLabel {...FORM_LABEL_PROPS}>{txt.name[lang]}:</FormLabel>
              <Input
                {...INPUT_COMMON_PROPS}
                placeholder="..."
                value={name}
                onChange={(e) => onSetState(e, setName)}
              />
              <FormErrorMessage {...ERROR_PROPS}>{`* ${errorType.name}`}</FormErrorMessage>
            </FormControl>
            {/* Form Sections - Phone Number */}
            <FormControl margin="16px 0" isInvalid={errorType.phone}>
              <FormLabel {...FORM_LABEL_PROPS}>{txt.hp[lang]}:</FormLabel>
              <Input
                {...INPUT_COMMON_PROPS}
                placeholder="..."
                value={phone}
                onChange={(e) => onSetState(e, setPhone, 'phone')}
              />
              <FormErrorMessage {...ERROR_PROPS}>{'* ' + errorType.phone}</FormErrorMessage>
            </FormControl>
            {/* Form Sections - Address */}
            {false && (
              <FormControl margin="8px 0" isInvalid={errorType.address}>
                <Input
                  {...INPUT_COMMON_PROPS}
                  height="40px"
                  placeholder={txt.address[lang]}
                  value={address}
                  onChange={(e) => onSetState(e, setAddress)}
                />
                <FormErrorMessage {...ERROR_PROPS}>{'* ' + errorType.address}</FormErrorMessage>
              </FormControl>
            )}
            {/* Form Sections - Attendance */}
            <FormControl margin="8px 0">
              <FormLabel {...FORM_LABEL_PROPS}>{txt.willYoutAttend[lang]}</FormLabel>
              <Select
                {...SELECT_COMMON_PROPS}
                value={attended}
                onChange={(e) => onSetState(e, setAttended)}
              >
                <option value={TYPE.YES} color="white">
                  {txt.willAttend[lang]}
                </option>
                <option value={TYPE.NO}>{txt.noAttend[lang]}</option>
              </Select>
            </FormControl>
            {/* Form Sections - Partner */}
            {attended === TYPE.YES && (
              <>
                <FormControl>
                  <FormLabel {...FORM_LABEL_PROPS}>{txt.willYouBringPartner[lang]}</FormLabel>
                  <Select
                    {...SELECT_COMMON_PROPS}
                    value={bringPartner}
                    onChange={(e) => onSetState(e, setBringPartner)}
                  >
                    {ENABLE_PARTNER_MODE ? (
                      <Fragment>
                        {(partner > 0 || partner === 0) && <option value={1}>1</option>}
                        {partner > 1 && <option value={2}>2</option>}
                        {partner > 2 && <option value={3}>3</option>}
                        {partner > 3 && <option value={4}>4</option>}
                        {partner > 4 && <option value={5}>5</option>}
                      </Fragment>
                    ) : (
                      <Fragment>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                      </Fragment>
                    )}
                  </Select>
                  {/* SET to TRUE if you want to add some information if user bring-partner YES */}
                  {bringPartner === TYPE.YES && false && (
                    <FormHelperText color="mainColorText" fontSize="10px" fontStyle="italic">
                      {txt.singleGuestInfo[lang]}
                    </FormHelperText>
                  )}
                </FormControl>
              </>
            )}
            <Center>
              <Button
                bgColor="mainColorText"
                isLoading={loading}
                marginTop="24px"
                size="sm"
                type="button"
                color="alternativeDark"
                onClick={onSubmitForm}
                borderRadius="8px"
                boxShadow="xl"
                {...BUTTON_PROPS}
              >
                {txt.submit[lang]}
              </Button>
            </Center>
          </Box>
        </WithAnimation>
      </Box>
    </Box>
  );
}

RSVPSection.propTypes = {
  lang: string,
};

RSVPSection.defaultProps = {
  lang: 'id',
};

export default React.memo(RSVPSection);
